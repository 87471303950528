@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.banner-text {
  font-family: "Open Sans", sans-serif;
}

@font-face {
  font-family: "Afrika Safari Rebuild St";
  src: local("Afrika Safari Rebuild St"),
    url("./fonts/afrika-font/Afrika\ Safari\ Rebuild\ St.ttf")
      format("truetype");
}
.text-animated {
  font-family: Afrika Safari Rebuild St;
}

.reset-css h1 {
  font-size: 2em;
  font-weight: bold;
}

.reset-css a {
  text-decoration: underline;
  color: #4c2db1;
}

.reset-css h2 {
  font-size: 1.5em;
  font-weight: bold;
}

.reset-css h3 {
  font-size: 1.17em;
  font-weight: bold;
}

.reset-css h4 {
  font-size: 1em;
  font-weight: bold;
}

.reset-css h5 {
  font-size: 0.83em;
  font-weight: bold;
}

.reset-css h6 {
  font-size: 0.67em;
  font-weight: bold;
}

.reset-css p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: "Tomato Grotesk";
}

.crop-container {
  border-radius: 10px;
}
.crop-media {
  border-radius: 10px;
}

.crop-area {
  border-radius: 6px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
